import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SVG from 'react-inlinesvg'

import {setAppIsPlaying, setJinxMode, setShowQualityUI} from './../store/setup'
import {seekTo, secondsToMinutesAndSeconds} from './_helpers'

function UiControlsTime(props) {
    const dispatch = useDispatch();

    const appIsPlaying = useSelector(state => state.setup.appIsPlaying);
    const jinxMode = useSelector(state => state.setup.jinxMode);
    const videoProgressTimer = useSelector(state => state.setup.videoProgressTimer);
    const currentVideoTime = useSelector(state => state.setup.currentVideoTime);
    const hideUI = useSelector(state => state.setup.hideUI);
    const showQualityUI = useSelector(state => state.setup.showQualityUI);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        let ele = document.documentElement;
        if (!isFullscreen) ele.requestFullscreen()
        else document.exitFullscreen();
        setIsFullscreen(!isFullscreen)
    }

    return (
            <div className={`UiControlsTime uicontrols ${hideUI ? 'hide' : ''}`}>
                <div className="playPauseBtn" onClick={() => dispatch(setAppIsPlaying(!appIsPlaying))}>
                    {appIsPlaying ? <SVG src="/pausebtn.svg" className="play"/>
                    : <SVG src="/playbtn.svg" className="play"/>}
                    </div>
                {/*jinxmode <input type="checkbox" checked={jinxMode} onChange={(e) => dispatch(setJinxMode(e.target.checked))}/><br/>*/}
                <input className="videoSeekTrack" step="0.1" type="range" min="0" max="100" value={videoProgressTimer} onChange={(e) => seekTo(e.target.value)}/>
                <div className="currentVideoTime">{secondsToMinutesAndSeconds(currentVideoTime)}</div>
                <SVG src="/settings.svg" className="settingsBtn" onClick={() => dispatch(setShowQualityUI(!showQualityUI))}/>
                <div className="fullscreenBtn" onClick={toggleFullscreen}/>
            </div>
        )
}

export default UiControlsTime