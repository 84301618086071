import {createSlice} from '@reduxjs/toolkit'

export const setupSlice = createSlice({
	name: 'setup',
	initialState: {
		initialisingApp: false,
		audioCtx: null,
		audioFiles: [
	        {name: 'one', file: 'audio_01.mp3'},
	        {name: 'two', file: 'audio_02.mp3'},
	        {name: 'three', file: 'audio_03.mp3'},
	        {name: 'four', file: 'audio_04.mp3'}
	        /*{name: 'one', file: 'test01.wav'},
	        {name: 'two', file: 'test02.wav'},
	        {name: 'three', file: 'test03.wav'},
	        {name: 'four', file: 'test04.wav'}*/
	      ],
		trackGainNotes: [],
		trackSource: [],
		audioBuffer: null,
		audioHasBeenLoaded: false,
		audioLoadProgress: '',
		appIsPlaying: false,
		videoIsLoading: true,
		videoHasBeenInitialised: false,
		lengthOfVideo: null,
		jinxMode: false,
		videoIsPlaying: false,
		activeTrackVideo: 0,
		activeTrackAudio: 0,
		currentVideoTime: 0,
		videoProgressTimer: 0,
		currentAppScreen: 'landing',
		gotoConcertAfterLoad: true,
		hideUI: false,
		showQualityUI: false
	},
	reducers: {
		setupApp1: (state, action) => {
			const audioCtx = action.payload
			state.initialisingApp = true;
			state.gotoConcertAfterLoad = true;
			state.audioCtx = audioCtx;
    
    		for (let i = 0; i < 4; i++) {
				state.trackGainNotes[i] = audioCtx.createGain();
	      		state.trackGainNotes[i].connect(audioCtx.destination)
	      		state.trackGainNotes[i].gain.value = 0;
    		}

    		state.trackGainNotes[0].gain.value = 1;
		}, 
		setupApp2: (state, action) => {
			state.audioBuffer = action.payload
			state.audioHasBeenLoaded = true;
		},
		stopAudio: (state, action) => {
			for (let i = 0; i < state.audioFiles.length; i++) {
		      state.trackSource[i].stop();
		    }
		},
		playAudio: (state, action) => {
			const pos = action.payload.pos
			const curTime = state.audioCtx.currentTime;
    		const posToUse = pos ? pos : 0;
			console.log('play', pos)

			for (let i = 0; i < state.audioFiles.length; i++) {
				if (state.trackSource[i]) state.trackSource[i].stop();
		    }	

			for (let i = 0; i < state.audioFiles.length; i++) {
		      state.trackSource[i] = null;
		      state.trackSource[i] = state.audioCtx.createBufferSource()
		      state.trackSource[i].buffer = state.audioBuffer[i];
		      state.trackSource[i].connect(state.trackGainNotes[i]);
		      state.trackSource[i].start(0,posToUse);
		    }
		},
		setVideoIsLoading: (state, action) => {
			state.videoIsLoading = action.payload
		},
		setVideoHasBeenInitialised: (state, action) => {
			state.videoHasBeenInitialised = action.payload
		},
		setLengthOfVideo: (state, action) => {
			state.lengthOfVideo = action.payload
		},
		setJinxMode: (state, action) => {
			state.jinxMode = action.payload
		},
		setAppIsPlaying: (state, action) => {
			state.appIsPlaying = action.payload
		},
		playVideo: (state) => {
			state.videoIsPlaying = true
		},
		pauseVideo: (state) => {
			state.videoIsPlaying = false
		},
		setActiveTrackVideo: (state, action) => {
			state.activeTrackVideo = action.payload
		},
		setActiveTrackAudio: (state, action) => {
			for (let i = 0; i < state.audioFiles.length; i++) {
		    	state.trackGainNotes[i].gain.value = 0;
		    }

		    state.trackGainNotes[action.payload].gain.value = 1;
			state.activeTrackAudio = action.payload
		},
		setVideoProgressTimer: (state, action) => {
			state.videoProgressTimer = action.payload
		},
		setCurrentVideoTime: (state, action) => {
			state.currentVideoTime = action.payload
		},
		gotoAppScreen: (state, action) => {
			state.currentAppScreen = action.payload
		},
		setGotoConcertAfterLoad: (state, action) => {
			state.gotoConcertAfterLoad = action.payload
		},
		setAudioLoadProgress: (state, action) => {
			state.audioLoadProgress = action.payload
		},
		setHideUI: (state, action) => {
			state.hideUI = action.payload
		},
		setShowQualityUI: (state, action) => {
			state.showQualityUI = action.payload
		}
	}
})

export const {
		setupApp1, 
		setupApp2, 
		playAudio,
		stopAudio,
		setVideoIsLoading, 
		setVideoHasBeenInitialised, 
		setLengthOfVideo,
		setJinxMode,
		setAppIsPlaying,
		playVideo,
		pauseVideo,
		setActiveTrackVideo,
		setActiveTrackAudio,
		setVideoProgressTimer,
		setCurrentVideoTime,
		gotoAppScreen,
		setGotoConcertAfterLoad,
		setAudioLoadProgress,
		setHideUI,
		setShowQualityUI
	} = setupSlice.actions

export default setupSlice.reducer