import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setGotoConcertAfterLoad} from './../store/setup.js'

import BackToLandingBtn from './BackToLandingBtn'

export default function AboutScreen(props) {
	const aboutSections = [
			{
				title: 'Composition',
				text: '<p>Lykkebækken (Glücksbecken Ger. Fortune Fountain Eng.) is an acoustic, audiovisual work based on an ancient instrument, the Chinese spouting bowl. With its roots in mysticism, the instrument is the inspiration for this ritualistic performance, where the four elements of earth (metal), water (water), air (breath and movement) and fire (light) are activated, before engaging on a path towards a metaphysical climax. The symbolic awakening of the instrument is mirrored by the occurrence of reflective resonating patterns in the sky above the performer, opening a portal to a parallel reality.</p><p>The performance is set in a dark, resonating room of contemplation.</p>'
			},
			{
				title: 'Instrument',
				text: '<p>The spouting bowl belongs to a family of musical instruments known as standing bells. The most well-known type of standing bell is the singing bowl, which is often associated with various meditation practices. Evidence of the existence of standing bells have been found dating back at least 3000 years.</p><p>The distinctive feature of the spouting bowl is a set of prominent handles. When rubbed, the handles cause the bowl to resonate, creating standing sound waves. If the bowl contains water, Chladni patterns form on the surface, producing jumping water droplets at pressure points near the bowl’s edge.</p><p>Spouting bowls first appeared during the Han Dynasty in China (202 BC to AD 220) and were believed to foster happiness, prolong life, and increase strength. The instrument used in Lykkebækken is a modern day copy of an ancient Chinese spouting bowl, produced in Germany from the finest materials, normally used in the production of church bells.</p>'
			},
			{
				title: 'Artistic Research',
				text: '<p>This website is the result of an artistic research project by Assistant Professor Christian Windfeld at The Royal Academy of Music, Aarhus/Aalborg. The purpose of the research was to look for an alternative way to document and publish an acoustic, audiovisual work, in which the audience actively take part in the editorial process of presenting the performance.</p><p>The outcome is an interface where audience members can choose between four different microphone positions and four different camera angles. These selections can be performed in real time or by skipping back and forth to experience any given moment of the performance. The interface thus allows audience members to experience the work from a wide range of sonic and visual perspectives.</p><p>The project is an attempt to embrace the autonomy and privacy afforded by personal computing as an alternative to passive listening. The interactive element to this work is an attempt to democratize the listening process.</p>'
			},
			{
				title: 'Credits',
				text: '<p>Concept, composition and performance: Christian Windfeld<br/>Audio: Henrik Winther<br/>Video: Klavs Kehlet<br/>Interface, design and programming: Troels Knud Damgaard<br/>Special thanks: Randi Schmidt, Anders Freestone and Knuds Kiosk</p><p>An artistic research project from:<br/><img class="djmlogo" src="/djm_logo.png"/><br/><br/><a href="https://musikkons.dk" target="_blank">musikkons.dk</a><br/><a href="http://christianwindfeld.com/" target="_blank">christianwindfeld.com</a><br/></p>'
			}
		]
	const [activeSection, setActiveSection] = useState(0)
	const dispatch = useDispatch();
	const currentAppScreen = useSelector(state => state.setup.currentAppScreen)

	useEffect(() => {
		if (currentAppScreen === 'about') dispatch(setGotoConcertAfterLoad(false))
	}, [currentAppScreen])

	return (
		<div className={`AboutScreen AppScreen ${currentAppScreen === 'about' ? 'active' : ''}`}>
            <BackToLandingBtn/>
            <div className="aboutContent">
            	<div className="navigation">
            		<div className="title">ABOUT</div>
            		<div className="nav">
            			{aboutSections.map((ele, i) => (
            				<div className={`singleNav ${activeSection === i ? 'active' : ''}`} key={i} onClick={() => setActiveSection(i)}>{ele.title}</div>
            			))}
            		</div>
            	</div>
            	<div className="main">
            		<div className="sectionTitle">{aboutSections[activeSection].title}</div>
            		<div className="text" dangerouslySetInnerHTML={{__html: aboutSections[activeSection].text}}/>
            	</div>
            </div>
		</div>
		)
}