import {useDispatch, useSelector} from 'react-redux'
import {setActiveTrackVideo} from './../store/setup'

function UiControlsVideo(props) {
  const dispatch = useDispatch();
  const cameras = [1,2,3,4]

  const activeTrackVideo = useSelector(state => state.setup.activeTrackVideo)
  const hideUI = useSelector(state => state.setup.hideUI);

  const audioNames = ['total', 'zoom', 'top', 'ceiling']

	return (
      <div className={`UiControlsMedia video uicontrols ${hideUI ? 'hide' : ''}`}>
        <div className="title">Video</div>
        <div className="controls">
          {cameras.map((ele, i) => (
              <div onClick={() => dispatch(setActiveTrackVideo(i))} key={i} className={`single ${i === activeTrackVideo ? 'active' : ''}`}><span>{audioNames[i]}</span></div>
            ))}
        </div>
      </div>
        )
}

export default UiControlsVideo