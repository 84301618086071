import {useDispatch, useSelector} from 'react-redux'

import {setActiveTrackAudio} from './../store/setup'

function UiControlsAudio(props) {
  const dispatch = useDispatch();

  const activeTrackAudio = useSelector(state => state.setup.activeTrackAudio)
  const audioFiles = useSelector(state => state.setup.audioFiles)
  const hideUI = useSelector(state => state.setup.hideUI);

  const audioNames = ['near', 'close', 'distant', 'far']

	return (
		  <div className={`UiControlsMedia audio uicontrols ${hideUI ? 'hide' : ''}`}>
        <div className="title">Audio</div>
        <div className="controls">
          {audioFiles.map((ele, i) => (
              <div onClick={() => dispatch(setActiveTrackAudio(i))} key={i} className={`single ${i === activeTrackAudio ? 'active' : ''}`}><span>{audioNames[i]}</span></div>
            ))}
        </div>
      </div>
        )
}

export default UiControlsAudio