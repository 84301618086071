import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {isDesktop, isSafari} from 'react-device-detect'

import {gotoAppScreen} from './../store/setup.js'

function LandingScreen(props) {
	const dispatch = useDispatch()
	const initialisingApp = useSelector(state => state.setup.initialisingApp)
	const audioHasBeenLoaded = useSelector(state => state.setup.audioHasBeenLoaded)
	const currentAppScreen = useSelector(state => state.setup.currentAppScreen)
	const audioLoadProgress = useSelector(state => state.setup.audioLoadProgress)

	useEffect(() => {
		console.log('is desktop', isDesktop);
		console.log('is safari', isSafari);
	}, [])

	return (
			<div className={`AppScreen LandingScreen ${currentAppScreen === 'landing' ? 'active' : ''}`}>
		      <h1 className="title">Lykkebækken</h1>
		      {isDesktop && !isSafari ? 
		      <>
		      	<div className="manchet">
		      		<div className={`text intro ${initialisingApp && !audioHasBeenLoaded ? '' : 'show'}`}>
			      		An acoustic audiovisual composition<br/>
			      		A interactive web-based publication<br/>
			      		A democratization of the listening process<br/>
			      		An artistic research project by Christian Windfeld
		      		</div>
		      		<div className={`text loading ${initialisingApp && !audioHasBeenLoaded ? 'show' : ''}`}>
			      		The performance is now loading. This may take a few minutes.<br/>
						It’s done to ensure the highest sound quality and smooth navigation of the interface.<br/>
						No data will be stored on your computer.<br/>
						Thank you for your patience.<br/>
						And please use headphones!
		      		</div>
		      	</div>
		      	<div className="actionWrapper">
			      {
			      	!initialisingApp && !audioHasBeenLoaded ? 
			      		<div className="btn initApp" onClick={props.initApp}>start<span className="circle"/></div>
			      	: initialisingApp && !audioHasBeenLoaded ?
			      		<div className="btn loading">{audioLoadProgress}<span className="circle"/></div>
			      	: <div className="btn" onClick={() => dispatch(gotoAppScreen('concert'))}><span className="sText">(audio loaded)</span>start<span className="circle"/></div>
			      }
			      <div className="btn" onClick={() => dispatch(gotoAppScreen('about'))}><span className="circle"/>ABOUT</div>
		    	</div>
		    	</>		      	
		    	: 
		    	<div className="manchet">
		    		You have accessed this websites with a device<br/>or browser that is unfortunately not supported.<br/><br/>
		    		Lykkebækken makes use of experimental web-technology,<br/>and therefor doesn't work on all browsers and devices.<br/>
		    		To experience Lykkebækken you must use either the Chrome or <br/>Firefox browser on a desktop device - we hope you will enjoy!
	    		</div>
		    	}
		    </div>
		)
}

export default LandingScreen