import {useEffect, useRef, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'

import {setVideoIsLoading, setVideoHasBeenInitialised, setLengthOfVideo, playAudio, setVideoProgressTimer, setCurrentVideoTime, stopAudio, pauseVideo, setShowQualityUI} from './../store/setup.js'

import {getVideoEleCurrentTime, playVideoEle, getVideoDuration, seekTo, pauseVideoEle} from './_helpers'

function VideoWrapper(props) {
    const dispatch = useDispatch();
    const videoEle = useRef();
    const [videoSize, setVideoSize] = useState({width: null, height: null, offsetY: null})

    // get from redux store
        const videoIsLoading = useSelector(state => state.setup.videoIsLoading)
        const videoHasBeenInitialised = useSelector(state => state.setup.videoHasBeenInitialised)
        const lengthOfVideo = useSelector(state => state.setup.lengthOfVideo);
        const jinxMode = useSelector(state => state.setup.jinxMode);
        const appIsPlaying = useSelector(state => state.setup.appIsPlaying);
        const activeTrackVideo = useSelector(state => state.setup.activeTrackVideo);
        const showQualityUI = useSelector(state => state.setup.showQualityUI);

        const activeTrackVideoRef = useRef();

        const qualities = [1080, 720, 480]
        const [activeQuality, setActiveQuality] = useState(0);
        const [videoQualityHasChanged, setVideoQualityHasChanged] = useState(false);
        const [savedVideoTime, setSavedVideoTime] = useState(null)
        const [timesTheVideoHasHaltedPlayback, setTimesTheVideoHasHaltedPlayback] = useState(0);

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setVideoPosition();
        })

        setVideoPosition();
    }, [])

    useEffect(() =>{
        activeTrackVideoRef.current = activeTrackVideo;
        setVideoPosition();
    }, [activeTrackVideo])

    const setVideoPosition = () => {
        const videoRatio = 0.5625;
        const screenRatio = window.innerHeight/window.innerWidth;
        const screenMode = screenRatio <= videoRatio ? 'landscape' : 'portrait';

        if (screenMode === 'landscape') {
            var offsetDir = activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 1 ? -1 : 1
            var videoOffset = (window.innerWidth*videoRatio-window.innerHeight)/2 * offsetDir;

            setVideoSize({
                width: window.innerWidth*2,
                height: 'auto', 
                transform: 'translateX(0) translateY('+videoOffset+'px)',
                right: activeTrackVideoRef.current === 2 || activeTrackVideoRef.current === 1 ? 0 : 'initial',
                left: activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 3 ? 0 : 'initial',
                top: activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 1 ? 0 : 'initial',
                bottom: activeTrackVideoRef.current === 3 || activeTrackVideoRef.current === 2 ? 0 : 'initial'
            })
        } else if (screenMode === 'portrait') {
            var offsetDir = activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 3 ? -1 : 1
            var videoOffset = ((window.innerHeight/(videoRatio*100)*100)-window.innerWidth)/2 * offsetDir;

            setVideoSize({
                width: 'auto',
                height: window.innerHeight*2, 
                transform: 'translateX('+videoOffset+'px) translateY(0)',
                right: activeTrackVideoRef.current === 2 || activeTrackVideoRef.current === 1 ? 0 : 'initial',
                left: activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 3 ? 0 : 'initial',
                top: activeTrackVideoRef.current === 0 || activeTrackVideoRef.current === 1 ? 0 : 'initial',
                bottom: activeTrackVideoRef.current === 3 || activeTrackVideoRef.current === 2 ? 0 : 'initial'
            })
        }

        console.log(window.innerWidth, window.innerHeight, screenRatio, screenMode, 'videoOffset: ', videoOffset, 'activeTrackVideo: ', activeTrackVideoRef.current)
    }

    const videoHasLoaded = () => {
        dispatch(setVideoHasBeenInitialised(true));
        dispatch(setVideoIsLoading(false));

        if (videoQualityHasChanged) {
            setVideoQualityHasChanged(false);
            seekTo(savedVideoTime/lengthOfVideo*100);
        }

        console.log('videoHasLoaded. appIsPlaying', appIsPlaying)

        if (appIsPlaying) {
            dispatch(playAudio({pos: getVideoEleCurrentTime()}));
            playVideoEle()
            console.log('starting video and sound at ', getVideoEleCurrentTime())
        }
    }

    const videoIsSeeking = () => {
        console.log('video is seeking - appIsPlaying', appIsPlaying)
        dispatch(setVideoIsLoading(true));
        if (appIsPlaying) {
            dispatch(stopAudio());
            /*dispatch(pauseVideo());*/
            pauseVideoEle()
        }
    }

    const videoIsWaiting = () => {
        videoIsSeeking();
        setTimesTheVideoHasHaltedPlayback(state => state+1)
    }

    useEffect(() => {
        if (videoHasBeenInitialised) {
          console.log('set length of video ONLY ONCE', videoEle.current.duration)
          dispatch(setLengthOfVideo(videoEle.current.duration));
        }
    }, [videoHasBeenInitialised])

    const updateTime = () => {
        dispatch(setVideoProgressTimer(getVideoEleCurrentTime()/lengthOfVideo*100));
        dispatch(setCurrentVideoTime(getVideoEleCurrentTime()));
    }

    const updateVideoQuality = (i) => {
        setSavedVideoTime(getVideoEleCurrentTime())
        setVideoQualityHasChanged(true);
        console.log('updating video quality', getVideoEleCurrentTime())
        setActiveQuality(i);
    }

    return (
            <div className={`VideoWrapper ${videoIsLoading ? 'loading' : ''} cam${activeTrackVideo}`}>
              <video style={videoSize} ref={videoEle} src={`https://lykkebaekken.com/all4_trimmed_${qualities[activeQuality]}.mp4`} id="video" onCanPlayThrough={videoHasLoaded} onSeeked={() => console.log('seeked')} onSeeking={videoIsSeeking} onWaiting={videoIsWaiting} onPause={() => console.log('main video paused')} onPlay={() => console.log('main video played')} onPlaying={() => console.log('video is playing')} onTimeUpdate={updateTime}/>
              {videoIsLoading ? <div className="loading">buffering video...</div> : null}
              <div className={`qualityPicker ${showQualityUI ? 'show' : ''}`}>
                <div className="close" onClick={() => dispatch(setShowQualityUI(false))}/>
                Video quality
                {qualities.map((ele, i) => (
                    <div key={i} className={`single ${activeQuality === i ? 'active' : ''}`} onClick={() => updateVideoQuality(i)}>{ele}p</div>
                ))}
              </div>
              {/*<div style={{color: '#fff',zIndex: 100, position: 'absolute'}}>{timesTheVideoHasHaltedPlayback}</div>*/}
            </div>
        )
}

export default VideoWrapper
