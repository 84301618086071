import {useDispatch, useSelector} from 'react-redux'
import {gotoAppScreen} from './../store/setup.js'

export default function BackToLandingBtn(props) {
	const dispatch = useDispatch();
  	const hideUI = useSelector(state => state.setup.hideUI);

	return (
			<div className={`BackToLandingBtn ${hideUI ? 'hide' : ''}`} onClick={() => {dispatch(gotoAppScreen('landing'))}}></div>
		)
}