import store from './../store/_store'
import {setVideoProgressTimer, stopAudio, playAudio, pauseVideo, setActiveTrackAudio, setActiveTrackVideo, setAppIsPlaying, setCurrentVideoTime} from './../store/setup'

export const getVideoEleCurrentTime = () => {
	const videoEle = document.getElementById('video');
	return videoEle.currentTime;
}

export const getVideoDuration = () => {
	const videoEle = document.getElementById('video');
	return videoEle.duration;
}

export const playVideoEle = () => {
	const videoEle = document.getElementById('video');
	videoEle.play();
}

export const pauseVideoEle = () => {
	const videoEle = document.getElementById('video');
	videoEle.pause();
}

export const seekTo = (destTime) => {
	const state = store.getState().setup;

    if (state.audioHasBeenLoaded && state.videoHasBeenInitialised) {
    	const newTimeInSec = destTime*state.lengthOfVideo/100;

    	console.log('seekto', destTime)
    	store.dispatch(setVideoProgressTimer(destTime))
    	store.dispatch(setCurrentVideoTime(newTimeInSec))

    	console.log('newTimeInSec', newTimeInSec)
      	/*videoEle.current.currentTime = newTimeInSec;*/
			const videoEle = document.getElementById('video');
			videoEle.currentTime = newTimeInSec;

      /*if (state.appIsPlaying) {
        store.dispatch(stopAudio());
        store.dispatch(pauseVideo());
      }*/
    }
}

export const keyPressed = (keyCode) => {
	const state = store.getState().setup;

    console.log(keyCode)
    switch(keyCode) {
	    case 81:
	        store.dispatch(setActiveTrackAudio(0))
	      break;
	    case 87:
	        store.dispatch(setActiveTrackAudio(1))
	      break;
	    case 69:
	        store.dispatch(setActiveTrackAudio(2))
	      break;
	    case 82:
	        store.dispatch(setActiveTrackAudio(3))
	      break;
	    case 84:
	        store.dispatch(setActiveTrackVideo(0))
	      break;
	    case 89:
	        store.dispatch(setActiveTrackVideo(1))
	      break;
	    case 85:
	        store.dispatch(setActiveTrackVideo(2))
	      break;
	    case 73:
	        store.dispatch(setActiveTrackVideo(3))
	      break;
	    case 32:
	         store.dispatch(setAppIsPlaying(!state.appIsPlaying))
	      break;
	    case 39:
    			const newTimeFW = state.currentVideoTime + 5;
	        	seekTo(newTimeFW/state.lengthOfVideo*100)
	      	break;
	    case 37:
    			const newTimeBW = state.currentVideoTime - 5;
	        	seekTo(newTimeBW/state.lengthOfVideo*100)
	      	break;
	    default:
	      console.log('cant find the correct key')
    }
}

export const secondsToMinutesAndSeconds = (seconds) => {
    let minutes = Math.floor(seconds/60)
    if (minutes < 10) minutes = '0'+minutes
    let remainingSeconds = Math.round(seconds%60);
    if (remainingSeconds < 10) remainingSeconds = '0'+remainingSeconds
    const minutesAndSeconds = minutes + ':' + remainingSeconds; 
    return minutesAndSeconds;
}