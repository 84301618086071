import {useRef, useState, useEffect} from 'react'
import {useSelector} from 'react-redux'

export default function BRollVideo() {
	const videoRef = useRef();
	const [videoLoaded, setVideoLoaded] = useState(false)
	const currentAppScreen = useSelector(state => state.setup.currentAppScreen)
 
	const videoHasLoaded = () => {
		console.log('video has loaded', videoLoaded)
		/*videoRef.current.play();*/
		setVideoLoaded(true);
		/*console.log(videoRef.current)*/
	}

	useEffect(() => {
		if (currentAppScreen === 'concert') setTimeout(() => {
			videoRef.current.pause();
		}, 500);
		else if (currentAppScreen === 'landing' || currentAppScreen === 'about') {
			if (videoLoaded) videoRef.current.play();
		}
	}, [currentAppScreen])

	return (
		<div className={`BRollVideo AppScreen ${currentAppScreen === 'landing' || currentAppScreen === 'about' ? 'active' : ''}`}>
              <video ref={videoRef} src="/broll.mp4" loop muted autoPlay onCanPlayThrough={videoHasLoaded} onPause={() => console.log('broll video was paused')} onPlay={() => console.log('broll video is playing')}/>
		</div>
		)
} 