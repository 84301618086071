import {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import BRollVideo from './BRollVideo'
import LandingScreen from './LandingScreen'
import ConcertScreen from './ConcertScreen'
import AboutScreen from './AboutScreen'

import {getVideoEleCurrentTime, playVideoEle, pauseVideoEle, keyPressed} from './_helpers'

import {setupApp1, setupApp2, playAudio, stopAudio, setAppIsPlaying, gotoAppScreen, setAudioLoadProgress, setHideUI} from './../store/setup.js'

//functions
  /*import keyPressed from './../functions/KeyCommands'*/

function App() {
  const dispatch = useDispatch();
    
  // redux
    const audioCtx = useSelector(state => state.setup.audioCtx);
    const videoHasBeenInitialised = useSelector(state => state.setup.videoHasBeenInitialised);
    const lengthOfVideo = useSelector(state => state.setup.lengthOfVideo);
    const appIsPlaying = useSelector(state => state.setup.appIsPlaying);
    const activeTrackAudio = useSelector(state => state.setup.activeTrackAudio);
    const audioFiles = useSelector(state => state.setup.audioFiles);
    const currentVideoTime = useSelector(state => state.setup.currentVideoTime);
    const audioHasBeenLoaded = useSelector(state => state.setup.audioHasBeenLoaded);
    const gotoConcertAfterLoad = useSelector(state => state.setup.gotoConcertAfterLoad);

    const timer = useRef(null)

  async function loadAudioFilesIntoMemory() {
    const aCtx = new AudioContext()

    dispatch(setupApp1(aCtx))
    
    /*audioCtx.current = new AudioContext();*/

    let arrayBuffers = [];
    for (let i = 0 ; i < audioFiles.length; i++) {
      const response = await fetch(audioFiles[i].file, {
        /*cache: 'only-if-cached'*/
      })
      console.log(audioFiles[i].file)

      // make loader
        const contentLength = response.headers.get('Content-Length');
        console.log(response)
        const reader = response.body.getReader();

        let receivedLength = 0;
        let chunks = [] 

        while(true) {
          const {done, value} = await reader.read();

          if (done) {
            break;
          }


          receivedLength += value.length;
          chunks.push(value)

          let loadTxt = `loading file ${i+1} of 4 : ${Math.round(receivedLength/contentLength*100)}%`;
          dispatch(setAudioLoadProgress(loadTxt))
        }

        /*reader.releaseLock();*/

        let chunksAll = new Uint8Array(receivedLength); // (4.1)
        let position = 0;
        for(let chunk of chunks) {
          chunksAll.set(chunk, position); // (4.2)
          position += chunk.length;
        }

        /*console.log(chunksAll)*/

      /*const arrayBuffer = await response.arrayBuffer();*/
      const arrayBuffer = chunksAll.buffer;
      console.log(arrayBuffer)
      arrayBuffers.push(arrayBuffer);
    }

    let newArray = [];
    for (let i = 0; i < arrayBuffers.length; i++) {
      let loadTxt = `loading audio ${i+1}/4 into buffer`
      dispatch(setAudioLoadProgress(loadTxt))
      const aBuffer = await aCtx.decodeAudioData(arrayBuffers[i]);
      /*trackGainNotes[i] = audioCtx.createGain();
      trackGainNotes[i].connect(audioCtx.destination)
      trackGainNotes[i].gain.value = 0;*/

      newArray.push(aBuffer)
    }

    dispatch(setupApp2(newArray));

    /*console.log(newArray)
    setAudioBuffer(newArray);*/
    
    /*trackGainNotes[0].gain.value = 1;*/
    /*setAudioHasBeenLoaded(true)*/
  }

  const initApp = async () => {
    await loadAudioFilesIntoMemory();
    /*dispatch(await setupApp())*/
  }

  useEffect(() => {
    if (audioHasBeenLoaded) {
      if (appIsPlaying) {
        playVideoEle();
        dispatch(playAudio({pos: getVideoEleCurrentTime()}));
      } else {
        pauseVideoEle();
        dispatch(stopAudio());
      }
    }
  }, [appIsPlaying])

  useEffect(() => {
    console.log('audioHasBeenLoaded', audioHasBeenLoaded)
    console.log('gotoConcertAfterLoad', gotoConcertAfterLoad)
    if (audioHasBeenLoaded && gotoConcertAfterLoad) dispatch(gotoAppScreen('concert'))
  }, [audioHasBeenLoaded])

  useEffect(() => {
    if (appIsPlaying) {
      timer.current = setTimeout(() => {
        dispatch(setHideUI(true))
      }, 2000)
    } else {
      dispatch(setHideUI(false))
    }

    return () => {
      clearTimeout(timer.current)
    }
  }, [appIsPlaying])

  const movingMouse = () => {
    clearTimeout(timer.current)
    dispatch(setHideUI(false))
    if (appIsPlaying) {
      timer.current = setTimeout(() => {
        dispatch(setHideUI(true))
      }, 3000)
    }
  }

  return (
    <div className="App" onKeyDown={e => keyPressed(e.keyCode)} onMouseMove={movingMouse} tabIndex="0">
        <BRollVideo/>
        <LandingScreen initApp={initApp}/>
        <AboutScreen/>
        {audioHasBeenLoaded ? <ConcertScreen/> : null}
    </div>
  );
}

export default App;
