import {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import VideoWrapper from './VideoWrapper'
import UiControlsAudio from './UiControlsAudio'
import UiControlsVideo from './UiControlsVideo'
import UiControlsTime from './UiControlsTime'
import BackToLandingBtn from './BackToLandingBtn'

import {setAppIsPlaying} from './../store/setup'

export default function ConcertScreen(props) {
	const currentAppScreen = useSelector(state => state.setup.currentAppScreen)
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentAppScreen === 'landing') setTimeout(() => {
            dispatch(setAppIsPlaying(false));
        }, 500);
    }, [currentAppScreen])

	return (
			<div className={`AppScreen ConcertScreen ${currentAppScreen === 'concert' ? 'active' : ''}`}>
                <UiControlsTime/>
                <UiControlsAudio/>
                <UiControlsVideo/>
                <VideoWrapper/>
                <BackToLandingBtn/>
            </div> 
		)
}